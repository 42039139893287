import {
  arrayUnion,
  collection,
  doc,
  getDocs,
  query,
  setDoc,
} from 'firebase/firestore'
import catalog from '../../../../mariah_sa_data/catalog.json'
import { IProduct, ProductPhoto } from '../interfaces/product'
import { auth, db } from '../../services/firebase'
import { RootProduct } from '../../brand/interfaces'

class ProductModel {
  public async createSyncData() {
    const signature = auth.currentUser?.uid
    const promises = catalog.hits.hits.map((product) => {
      return setDoc(doc(db, 'products', product._id), product)
    })

    Promise.all(promises)

    await setDoc(doc(db, 'data', 'userMaster'), {
      updates: arrayUnion({
        dbVersion: new Date().toISOString(),
        signature,
      }),
    })
  }

  public async getAll() {
    const ptoducts: RootProduct[] = []

    const data = await getDocs(query(collection(db, 'products')))
    data.forEach((doc) => ptoducts.push(doc.data() as RootProduct))

    return ptoducts.flatMap((item) => [
      {
        id: item._id,
        code: item._source.ref_text,
        name: item._source.nome_text,
        price: item._source.pre_o_number,
        photo: item._source.fotos_list_image.flatMap((item, index) => [
          {
            id: String(index),
            uri: item,
          } as ProductPhoto,
        ]),
      } as IProduct,
    ])
  }
}

export const Product = new ProductModel()
