import React, { useState } from 'react'
import {
  View,
  Input,
  HStack,
  AddIcon,
  Container,
  MinusIcon,
  Pressable,
  IContainerProps,
} from 'native-base'

interface ICounter {
  limit?: number
  initialValue?: number
  containerProps?: IContainerProps
  countValue?: (value: number) => void
}

export const Counter = ({
  limit,
  countValue,
  initialValue,
  containerProps,
}: ICounter) => {
  const [value, setValue] = useState(initialValue ?? 1)

  return (
    <Container {...containerProps}>
      <HStack
        borderRadius="lg"
        alignItems={'center'}
        backgroundColor={value === 0 ? '#ff000080' : 'blue.200'}
      >
        <Pressable
          marginX={'4'}
          onPress={() => {
            if (value >= 1) {
              countValue && countValue(value - 1)
              setValue((v) => v - 1)
            }
          }}
        >
          {({ isPressed }) => (
            <View opacity={isPressed ? 0.3 : 1}>
              <MinusIcon />
            </View>
          )}
        </Pressable>

        <Input value={String(value)} editable={false} isDisabled width={'10'} />

        <Pressable
          marginX={'4'}
          onPress={() => {
            if (limit) {
              if (value < limit) {
                countValue && countValue(value + 1)
                setValue((v) => v + 1)
              }
            } else {
              countValue && countValue(value + 1)
              setValue((v) => v + 1)
            }
          }}
        >
          {({ isPressed }) => (
            <View opacity={isPressed ? 0.3 : 1}>
              <AddIcon />
            </View>
          )}
        </Pressable>
      </HStack>
    </Container>
  )
}
