import { auth, db } from '../../services/firebase'
import { addDoc, collection, getDocs, query, where } from 'firebase/firestore'

import { Storage } from './storage'
import { ISale } from '../interfaces/sale'
import { ProductStorage } from '../interfaces/product'
import { IClient } from '../interfaces/client'
import { Client } from './client'

class SaleModel {
  public async getAll() {
    const data: ISale[] = []
    const signature = auth.currentUser?.uid

    const querySales = await getDocs(
      query(collection(db, 'sales'), where('signature', '==', signature))
    )

    querySales.forEach((doc) => data.push(doc.data() as ISale))

    return data
  }

  public async create(value: ISale, clients: IClient[]) {
    const { products } = value
    const signature = auth.currentUser?.uid
    let clientData: IClient | undefined = undefined

    const updatedClient = async () => {
      const oldClient = clients.find((item) => item.id === value.client?.id)

      if (!oldClient && value?.client) {
        clientData = await Client.create(value?.client)
      } else if (
        oldClient &&
        value.client &&
        Number(value.client?.credit) !== Number(oldClient.credit)
      ) {
        clientData = await Client.update(value.client)
      } else clientData = oldClient
    }

    const updatedProducts = products.map((product) => {
      const storages = product.storages ?? []
      const quantity = product.quantity ?? 0

      return {
        ...product,
        storages: this.substractQuantityFromOrders(storages, quantity),
      }
    })

    const updateStorages = updatedProducts.map((product) => {
      return Storage.update(product)
    })

    if (value?.client) {
      await updatedClient()
    }

    await Promise.all(updateStorages)

    await addDoc(collection(db, 'sales'), {
      ...value,
      products: updatedProducts,
      client: clientData ?? null,
      signature,
    })
  }

  private substractQuantityFromOrders = (
    storages: ProductStorage[],
    quantity: number
  ) => {
    let result: ProductStorage[] = storages
    let q = quantity

    storages.forEach((storage) => {
      if (storage.quantity && storage.quantity <= q) {
        result = [
          ...result.filter((item) => item.orderName !== storage.orderName),
          { ...storage, quantity: 0 },
        ]
        q -= storage.quantity
      } else if (storage.quantity && storage.quantity > q) {
        result = [
          ...result.filter((item) => item.orderName !== storage.orderName),
          { ...storage, quantity: storage.quantity - q },
        ]
        q = 0
      }
    })

    return result
  }
}

export const Sale = new SaleModel()
