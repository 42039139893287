import { signInWithEmailAndPassword } from 'firebase/auth'
import { auth, db } from '../../services/firebase'
import { MasterUserProps } from '../interfaces/user'
import { doc, getDoc } from 'firebase/firestore'

class UserModel {
  public async login(values: { email: string; password: string }) {
    const { email, password } = values
    await signInWithEmailAndPassword(auth, email, password)
  }

  public async logout() {
    await auth.signOut()
  }

  public async getUserMasterData() {
    const response = await getDoc(doc(db, 'data', 'userMaster'))
    return response.data() as MasterUserProps
  }
}

export const User = new UserModel()
