import React from 'react'
import { FormikProps } from 'formik'
import { Button, Divider, FlatList, HStack, Text, View } from 'native-base'

import { Alert } from '../../../components/Alert'
import { Counter } from '../../../components/Couter'
import { SaleProductItem } from './ProductItem'
import { convertCurrency } from '../../../util/currencyConverter'
import { InitialValuesProps } from '../../../data/interfaces/order'

type ItemProps = InitialValuesProps['products'][0]

export const Quantifier = ({
  values,
  submitForm,
  setFieldValue,
}: Partial<FormikProps<InitialValuesProps>>) => {
  const total =
    values?.products.reduce(
      (acc, item) => acc + (item.price ?? 0) * (item?.quantity ?? 1),
      0
    ) ?? 0

  const handleSend = () => {
    const removedUnquantified = values?.products
      .filter((item) => item.quantity !== 0)
      .map((product) =>
        !product.quantity ? { ...product, quantity: 1 } : product
      )

    if (removedUnquantified?.length === 0) {
      Alert.alert('nenhum produto tem quantidade definida')
    } else {
      setFieldValue && setFieldValue('products', removedUnquantified)
      submitForm && submitForm()
    }
  }

  const quatifier = (item: ItemProps, quantity: number) => {
    const quantifiedProduct = { ...item, quantity }
    const newList = values?.products.map((product) =>
      product.id === item.id ? quantifiedProduct : product
    )

    setFieldValue && setFieldValue('products', newList)
  }

  const renderItem = ({ item }: { item: ItemProps }) => {
    const initialQuantity = values?.products.find(
      (i) => i.id === item.id
    )?.quantity

    let limit = item?.storages?.length ? 0 : undefined
    if (limit !== undefined) {
      item?.storages?.forEach((p) => {
        limit = (limit ?? 0) + (p.quantity ?? 0)
      })
    }

    return (
      <>
        <SaleProductItem item={item} notSelectable />
        <Counter
          limit={limit}
          containerProps={{
            top: '12',
            position: 'absolute',
            alignSelf: 'flex-end',
          }}
          initialValue={initialQuantity ?? 1}
          countValue={(quantity) => quatifier(item, quantity)}
        />
      </>
    )
  }

  return (
    <>
      <FlatList
        renderItem={renderItem}
        data={values?.products}
        ListFooterComponent={<View height={'20'} />}
      />

      <Divider height={'1.5'} />

      <HStack height={'16'} justifyContent={'center'} alignItems={'center'}>
        <View
          flex={1}
          height={'10'}
          paddingX={'2'}
          marginRight={'4'}
          borderRadius={'sm'}
          bgColor={'primary.500'}
          alignItems={'flex-end'}
          justifyContent={'space-between'}
        >
          <Text>Total</Text>
          <Text>{convertCurrency(total)}</Text>
        </View>

        <Button onPress={handleSend} width={'2/5'}>
          Próximo
        </Button>
      </HStack>
    </>
  )
}
