import appjson from '../../../app.json'
import React from 'react'
import * as Yup from 'yup'
import { Formik } from 'formik'
import { useWindowDimensions } from 'react-native'
import { useMutation } from '@tanstack/react-query'
import { Button, Image, Text, View } from 'native-base'

import { User } from '../../data/models/user'
import { Alert } from '../../components/Alert'
import SoftShopLogo from '../../images/logo_full.png'
import BrandLogo from '../../brand/images/brand_p.png'
import { Container } from '../../components/Container'
import { FormInput } from '../../components/FormInput'

export const Login = () => {
  const { width } = useWindowDimensions()

  const { mutate: loginMutate, isLoading: loadingLogin } = useMutation({
    mutationFn: (user: { email: string; password: string }) => User.login(user),
    onError: (error) => Alert.alert(error),
  })

  return (
    <>
      <Container style={{ padding: 25 }}>
        <View flex={1} justifyContent={'center'}>
          <Image
            h={width / 2}
            resizeMode="contain"
            source={BrandLogo}
            alt="mariah_sa_logo"
          />

          <View alignSelf={'center'} width={width / 2.5} marginTop={30}>
            <Text textAlign={'center'} color={'primary.400'}>
              powered by
            </Text>
            <Image
              w={width / 2.5}
              h={30}
              resizeMode="contain"
              source={SoftShopLogo}
              alt="soft_shop_logo"
            />
          </View>
        </View>

        <Formik
          initialValues={{
            email: '',
            password: '',
          }}
          onSubmit={loginMutate}
          validationSchema={Yup.object().shape({
            email: Yup.string()
              .email('Email inválido')
              .required('Campo obrigatório'),
            password: Yup.string()
              .min(6, 'Mínimo 6 caracteres')
              .required('Campo obrigatório'),
          })}
        >
          {({ errors, values, submitForm, setFieldValue }) => (
            <>
              <View marginBottom={'10'}>
                <FormInput
                  label="email"
                  autoCapitalize={'none'}
                  keyboardType="email-address"
                  value={values.email}
                  errorMessage={errors.email}
                  onChangeText={(email) => setFieldValue('email', email)}
                />
                <FormInput
                  label="senha"
                  type="password"
                  value={values.password}
                  errorMessage={errors.password}
                  onChangeText={(password) =>
                    setFieldValue('password', password)
                  }
                />
              </View>
              <Button isLoading={loadingLogin} onPress={submitForm}>
                Entrar
              </Button>
            </>
          )}
        </Formik>
        <Text position={'absolute'} bottom={'0'} right={'0.5'}>
          {`v.${appjson.expo.version}`}
        </Text>
      </Container>
    </>
  )
}
