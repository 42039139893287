import { IToastProps, Toast } from 'native-base'

class AlertClass {
  public alert = (message: unknown | string, props?: IToastProps) => {
    let title: string = message as string
    if (message instanceof Error) title = message.message

    return Toast.show({
      title,
      placement: props?.placement || 'top',
      ...props,
    })
  }
}

export const Alert = new AlertClass()
