import { IInputProps, Input, Text, View } from 'native-base'
import { IViewProps } from 'native-base/lib/typescript/components/basic/View/types'
import React from 'react'
import { formatWithMask, Mask } from 'react-native-mask-input'

interface IFormInput extends IInputProps {
  mask?: Mask
  label?: string
  errorMessage?: string
  containerProps?: IViewProps
}

export const FormInput = ({
  mask,
  label,
  errorMessage,
  containerProps,
  ...props
}: IFormInput) => {
  return (
    <View {...containerProps}>
      <Text>{label}</Text>
      {mask ? (
        <Input
          {...props}
          onChangeText={(text) => {
            props.onChangeText?.(formatWithMask({ text, mask }).masked)
          }}
        />
      ) : (
        <Input {...props} />
      )}
      <Text color={'warning.500'}>{errorMessage}</Text>
    </View>
  )
}
