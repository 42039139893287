import React from 'react'
import { FormikProps } from 'formik'
import { useWindowDimensions } from 'react-native'
import {
  Actionsheet,
  Divider,
  FlatList,
  HStack,
  Icon,
  IconButton,
  Spacer,
  Text,
  View,
} from 'native-base'
import { InitialValuesProps } from '../../../data/interfaces/sale'
import { FormInput } from '../../../components/FormInput'
import { Masks } from 'react-native-mask-input'
import { AntDesign } from '@expo/vector-icons'
import { Cashier } from '../../../components/Cashier'
import { paymentMethods } from '../../../data/constants/paymentMethods'

export const Payment = ({
  values,
  errors,
  setFieldValue,
  submitForm,
}: Partial<FormikProps<InitialValuesProps>>) => {
  const { height } = useWindowDimensions()
  const [showPaymentMethods, setShowPaymentMethods] = React.useState(false)

  const renderItem = ({
    item,
  }: {
    item: InitialValuesProps['products'][0]
  }) => {
    return (
      <HStack justifyContent={'space-between'}>
        <Text>{`${item.name} - ${item.code}`}</Text>
        <Text>{`${item.quantity}`}</Text>
      </HStack>
    )
  }

  const arrowIcon = () => (
    <IconButton
      height={'8'}
      onPress={() => setShowPaymentMethods(true)}
      icon={<Icon name="down" as={AntDesign} />}
    />
  )

  const methodsList = () => (
    <Actionsheet
      isOpen={showPaymentMethods}
      onClose={() => setShowPaymentMethods(false)}
    >
      <Actionsheet.Content>
        {paymentMethods.map((method) => (
          <Actionsheet.Item
            key={method.id}
            onPress={() => {
              setFieldValue && setFieldValue('paymentMethod', method.id)
              setShowPaymentMethods(false)
            }}
          >
            {method.name}
          </Actionsheet.Item>
        ))}
      </Actionsheet.Content>
    </Actionsheet>
  )

  const methodDetails = () => {
    const optios: Record<string | 'isDefault', string | null> = {
      '2': 'Parcelas',
      '99': 'Descreva',
      isDefault: null,
    }

    const render = optios[values?.paymentMethod ?? 'isDefault']
    return render ? (
      <FormInput
        containerProps={{
          width: '28%',
        }}
        label={render}
        keyboardType={render === 'Parcelas' ? 'numeric' : 'default'}
        onChangeText={(methodDetails) =>
          setFieldValue && setFieldValue('methodDetails', methodDetails)
        }
        errorMessage={errors?.methodDetails}
      />
    ) : null
  }

  const InputLeftElement = (
    <Text lineHeight={'2xs'} margin={'2'}>
      {'%'}
    </Text>
  )

  return (
    <>
      <View flex={1}>
        {methodsList()}

        <FlatList
          ListHeaderComponent={() => (
            <>
              <HStack justifyContent={'space-between'}>
                <Text>Produto</Text>
                <Text>Quantidade</Text>
              </HStack>
            </>
          )}
          data={values?.products}
          renderItem={renderItem}
          contentContainerStyle={{ height: height * 0.5 }}
        />

        <Divider marginBottom={'2'} />
        <HStack width={'full'}>
          <FormInput
            label="Forma de Pagamento"
            value={
              paymentMethods.find(
                (method) => method.id === values?.paymentMethod
              )?.name ?? ''
            }
            editable={false}
            rightElement={arrowIcon()}
            containerProps={{
              width: '2/3',
              marginRight: '4',
            }}
            errorMessage={errors?.paymentMethod}
          />

          {methodDetails()}
        </HStack>

        <HStack width={'full'} justifyContent={'flex-between'}>
          <FormInput
            label="Desconto"
            InputLeftElement={InputLeftElement}
            keyboardType="numeric"
            value={values?.discount}
            onChangeText={(discount) =>
              setFieldValue && setFieldValue('discount', discount)
            }
            errorMessage={errors?.discount}
          />

          <Spacer />

          <FormInput
            label="Data"
            keyboardType="numeric"
            containerProps={{
              width: '1/3',
            }}
            mask={Masks.DATE_DDMMYYYY}
            value={values?.date}
            onChangeText={(date) =>
              setFieldValue && setFieldValue('date', date)
            }
            errorMessage={errors?.date}
          />
        </HStack>
      </View>
      <Cashier values={values} handleNext={submitForm} />
    </>
  )
}
