import React from 'react'
import { FormikProps } from 'formik'
import { Masks } from 'react-native-mask-input'
import { useWindowDimensions } from 'react-native'
import { Divider, FlatList, HStack, Spacer, Text, View } from 'native-base'

import { Alert } from '../../../components/Alert'
import { Cashier } from '../../../components/Cashier'
import { FormInput } from '../../../components/FormInput'
import { IOrder, InitialValuesProps } from '../../../data/interfaces/order'

export const Finish = ({
  orders,
  values,
  submitForm,
  setFieldValue,
  buttonLoading,
}: Partial<FormikProps<InitialValuesProps>> & {
  orders: IOrder[]
  buttonLoading?: boolean
}) => {
  const { height } = useWindowDimensions()

  const handleSend = () => {
    if (!values?.orderName) return Alert.alert('Preencha o nome do pedido')
    if (!values?.date) return Alert.alert('Preencha a data do pedido')
    if (orders?.some((item) => item.orderName === values?.orderName))
      return Alert.alert('Já existe um pedido com esse nome')

    submitForm && submitForm()
  }

  const renderItem = ({
    item,
  }: {
    item: InitialValuesProps['products'][0]
  }) => {
    return (
      <HStack justifyContent={'space-between'}>
        <Text>{`${item.name} - ${item.code}`}</Text>
        <Text>{`${item.quantity}`}</Text>
      </HStack>
    )
  }

  const InputLeftElement = (
    <Text lineHeight={'2xs'} margin={'2'}>
      {'%'}
    </Text>
  )

  return (
    <>
      <View flex={1}>
        <FlatList
          ListHeaderComponent={() => (
            <>
              <HStack justifyContent={'space-between'}>
                <Text>Produto</Text>
                <Text>Quantidade</Text>
              </HStack>
            </>
          )}
          data={values?.products}
          renderItem={renderItem}
          contentContainerStyle={{ height: height * 0.5 }}
        />

        <Divider height={'1.5'} />

        <View width={'full'} alignSelf={'flex-end'}>
          <FormInput
            label="Pedido"
            keyboardType="numeric"
            value={values?.orderName}
            onChangeText={(orderName) =>
              setFieldValue && setFieldValue('orderName', orderName)
            }
          />

          <HStack width={'full'} justifyContent={'flex-between'}>
            <FormInput
              label="Desconto"
              InputLeftElement={InputLeftElement}
              keyboardType="number-pad"
              value={values?.discount}
              onChangeText={(discount) =>
                setFieldValue && setFieldValue('discount', discount)
              }
            />

            <Spacer />

            <FormInput
              label="Data"
              keyboardType="numeric"
              containerProps={{
                width: '1/3',
              }}
              mask={Masks.DATE_DDMMYYYY}
              value={values?.date}
              onChangeText={(date) =>
                setFieldValue && setFieldValue('date', date)
              }
            />
          </HStack>
        </View>
      </View>

      <Cashier
        isFinish
        values={values}
        handleNext={handleSend}
        isLoading={buttonLoading}
      />
    </>
  )
}
