import React from 'react'
import { ViewProps, SafeAreaView } from 'react-native'

export const Container = ({ children, ...props }: ViewProps) => {
  return (
    <SafeAreaView
      {...props}
      style={[
        {
          flex: 1,
          backgroundColor: 'white',
          padding: 5,
        },
        props.style,
      ]}
    >
      {children}
    </SafeAreaView>
  )
}
