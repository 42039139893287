import {
  collection,
  deleteDoc,
  doc,
  getDocs,
  query,
  setDoc,
  updateDoc,
  where,
} from 'firebase/firestore'
import { IClient } from '../interfaces/client'
import { auth, db } from '../../services/firebase'
import { uidGen } from '../../util/uidGenerator'

class ClientModel {
  public async getAll() {
    const signature = auth.currentUser?.uid
    const data: IClient[] = []

    const queryProducts = await getDocs(
      query(collection(db, 'clients'), where('signature', '==', signature))
    )

    queryProducts.forEach((doc) => data.push(doc.data() as IClient))
    return data
  }

  public async create(client: IClient) {
    const signature = auth.currentUser?.uid
    const id = client.id ?? uidGen()

    const data = {
      ...client,
      signature,
      credit: client.credit ?? 0,
    }

    await setDoc(doc(db, 'clients', id), data)
    return data
  }

  public async update(client: IClient) {
    const signature = auth.currentUser?.uid

    if (client.id) {
      const productRef = doc(db, 'clients', client.id)
      const data = {
        ...client,
        signature,
      }

      await updateDoc(productRef, data)
      return data
    }
  }

  public async delete(id: string) {
    deleteDoc(doc(db, 'clients', id))
  }
}

export const Client = new ClientModel()
