import React, { useState } from 'react'
import { Pressable } from 'react-native'
import { Image, HStack, Text, VStack } from 'native-base'

import { IProduct } from '../../../data/interfaces/product'
import { convertCurrency } from '../../../util/currencyConverter'
import { IHStackProps } from 'native-base/lib/typescript/components/primitives/Stack/HStack'

interface IProductItem extends IHStackProps {
  item: IProduct
  isSelected?: boolean
  notSelectable?: boolean
  selectedItem?: (value: { item: IProduct; isSelected: boolean }) => void
}

export const SaleProductItem = ({
  item,
  isSelected,
  selectedItem,
  notSelectable,
  ...props
}: IProductItem) => {
  const [selected, setSelected] = useState(isSelected)

  return (
    <Pressable
      onPress={() => {
        !notSelectable && setSelected((v) => !v)
        selectedItem && selectedItem({ item, isSelected: !selected })
      }}
    >
      <HStack {...props} margin={'1'} bgColor={selected ? 'blue.300' : 'white'}>
        <Image
          width={'20'}
          height={'20'}
          rounded={'lg'}
          alt={`photo_${item.name}`}
          source={
            item?.photo && item.photo[0]?.uri
              ? { uri: item.photo[0]?.uri }
              : undefined
          }
        />

        <VStack paddingX={'2'}>
          <Text>{`${item.name} - ${item.code}`}</Text>
          <Text>{convertCurrency(Number(item.price))}</Text>
        </VStack>
      </HStack>
    </Pressable>
  )
}
