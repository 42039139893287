import React from 'react'
import { Container } from '../../components/Container'
import { AddIcon, Fab } from 'native-base'
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query'
import { Client as ClientModel } from '../../data/models/client'
import { ClientsModal } from './Components/ClientsModal'
import { ClientsList } from './Components/ClientsList'
import { IClient } from '../../data/interfaces/client'
import { Alert } from '../../components/Alert'

export const MyClients = () => {
  const queryClient = useQueryClient()
  const initialClientData = {
    name: '',
    phone: '',
    birthDate: '',
    credit: 0,
  }

  const [showModal, setShowModal] = React.useState(false)
  const [editData, setEditData] = React.useState<IClient>(initialClientData)

  const { data: clients, isLoading: loadingClients } = useQuery(
    ['clients'],
    () => ClientModel.getAll()
  )

  const { mutate: addClient, isLoading: loadingAdd } = useMutation({
    mutationFn: (data: IClient) => ClientModel.create(data),
    onSuccess: () => {
      Alert.alert('Cliente adicionado com sucesso')
      queryClient.invalidateQueries(['clients'])
      setShowModal(false)
    },
  })

  const { mutate: editClient, isLoading: loadingEdit } = useMutation({
    mutationFn: (data: IClient) => ClientModel.update(data),
    onSuccess: () => {
      Alert.alert('Cliente atualizado com sucesso')
      queryClient.invalidateQueries(['clients'])
      setShowModal(false)
    },
  })

  const { mutate: deleteClient } = useMutation({
    mutationFn: (id: string) => ClientModel.delete(id),
    onSuccess: () => queryClient.invalidateQueries(['clients']),
  })

  const handleEdit = (data?: IClient) => {
    if (data) {
      const birthDate = data?.birthDate.split('-').reverse().join('/')
      setEditData({ ...data, birthDate })
    }
    setShowModal(true)
  }

  return (
    <Container>
      <ClientsModal
        isOpen={showModal}
        handleAdd={addClient}
        initialData={editData}
        handleEdit={editClient}
        onClose={() => setShowModal(false)}
        isLoading={loadingAdd || loadingEdit}
      />

      <ClientsList
        clients={clients ?? []}
        isLoading={loadingClients}
        handleEdit={handleEdit}
        handleDelete={({ id }) => id && deleteClient(id)}
      />

      <Fab
        size={'lg'}
        right={'10'}
        bottom={'10'}
        icon={<AddIcon />}
        renderInPortal={false}
        onPress={() => handleEdit(initialClientData)}
      />
    </Container>
  )
}
