/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect } from 'react'
import { createContext, useState } from 'react'
import { IUser } from '../data/interfaces/user'
import { useQuery } from '@tanstack/react-query'
import { User } from '../data/models/user'
import { auth } from '../services/firebase'

interface IUserContext {
  user?: IUser
  setUser?: React.Dispatch<React.SetStateAction<IUser>>
}

export const UserContext = createContext<IUserContext>({
  setUser: () => undefined,
  user: undefined,
})

export const UserContextProvider = ({
  children,
}: {
  children: React.ReactNode
}) => {
  const [user, setUser] = useState<IUser>({
    uid: auth.currentUser?.uid || '',
    email: auth.currentUser?.email || '',
    displayName: auth.currentUser?.displayName || '',
    isMasterUser: undefined,
  })

  useEffect(() => {
    setUser({
      ...user,
      uid: auth.currentUser?.uid || '',
      email: auth.currentUser?.email || '',
      displayName: auth.currentUser?.displayName || '',
    })
  }, [auth.currentUser])

  useQuery(['master-data', auth.currentUser], User.getUserMasterData, {
    onSuccess: (data) => setUser({ ...user, isMasterUser: data }),
    staleTime: Infinity,
  })

  return (
    <UserContext.Provider value={{ setUser, user }}>
      {children}
    </UserContext.Provider>
  )
}
