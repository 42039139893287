export const paymentMethods = [
  { id: '1', name: 'Dinheiro' },
  { id: '2', name: 'Cartão de Crédito' },
  { id: '3', name: 'Cartão de Débito' },
  { id: '4', name: 'Pix' },
  { id: '5', name: 'Transferência Bancária' },
  { id: '6', name: 'Cheque' },
  { id: '7', name: 'Boleto Bancário' },
  { id: '99', name: 'Outros' },
]
