import { addDoc, collection, getDocs, query, where } from 'firebase/firestore'
import { auth, db } from '../../services/firebase'
import { IOrder } from '../interfaces/order'

class OrderModel {
  public async getAll() {
    const signature = auth.currentUser?.uid
    const data: IOrder[] = []

    const queryProducts = await getDocs(
      query(collection(db, 'orders'), where('signature', '==', signature))
    )

    queryProducts.forEach((doc) => data.push(doc.data() as IOrder))

    return data
  }

  public async create(data: IOrder) {
    const signature = auth.currentUser?.uid

    return await addDoc(collection(db, 'orders'), {
      ...data,
      signature,
    })
  }
}

export const Order = new OrderModel()
