import React from 'react'
import { Card, Divider, FlatList, Text, View } from 'native-base'
import { useQuery } from '@tanstack/react-query'

import { Order } from '../../data/models/order'
import { Container } from '../../components/Container'
import { IOrder } from '../../data/interfaces/order'
import { Loading } from '../../components/Loading'
import { convertCurrency } from '../../util/currencyConverter'

export const MyOrders = () => {
  const { data: orders, isInitialLoading: loadingOrders } = useQuery(
    ['orders'],
    () => Order.getAll()
  )

  const renderItem = ({ item }: { item: IOrder }) => {
    const total = item.products.reduce(
      (acc, p) => acc + (p.price ?? 0) * (p.quantity ?? 1),
      0
    )
    const discount = (Number(item.discount) ?? 1) / 100
    const totalWithDiscount = total - total * discount

    return (
      <Card margin={'2'}>
        <Text alignSelf={'flex-end'}>
          {`#${item.orderName} - ${item.date}`}
        </Text>

        <Divider />
        {item.products.map((product) => (
          <View key={product.id}>
            <Text>{`${product.quantity}x ${product.name}`}</Text>
          </View>
        ))}

        <Divider width={'4/5'} />
        <View width={'4/5'} alignItems={'flex-end'}>
          <Text
            textDecorationLine={item.discount > 0 ? 'line-through' : undefined}
          >
            {`Total: ${convertCurrency(total)}`}
          </Text>

          {item.discount ? (
            <>
              <Text>{`desconto de ${item.discount}%`}</Text>
              <Text>{`Total: ${convertCurrency(totalWithDiscount)}`}</Text>
            </>
          ) : null}
        </View>
      </Card>
    )
  }

  if (loadingOrders) return <Loading />
  return (
    <Container>
      <FlatList data={orders ?? []} renderItem={renderItem} />
    </Container>
  )
}
