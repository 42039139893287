import React, { useState, useEffect } from 'react'
import { NavigationContainer } from '@react-navigation/native'
import { MainStackRouter } from './Main'
import { AuthStackRouter } from './Auth'
import { onAuthStateChanged } from 'firebase/auth'
import { auth } from '../services/firebase'
import { useQueryClient } from '@tanstack/react-query'
import { Loading } from '../components/Loading'

export default function Router() {
  const [isLoggedin, setIsLoggedIn] = useState(false)
  const queryClient = useQueryClient()
  const [isLoading, setIsLoading] = useState(true)

  useEffect(() => {
    const unsub = onAuthStateChanged(auth, (user) => {
      const userData = {
        email: user?.email,
        uid: user?.uid,
        displayName: user?.displayName,
      }

      queryClient.setQueryData(['user'], userData)
      setIsLoading(false)
      setIsLoggedIn(!!user)
    })
    return () => unsub()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  if (isLoading) return <Loading />
  return (
    <NavigationContainer>
      {isLoggedin ? <MainStackRouter /> : <AuthStackRouter />}
    </NavigationContainer>
  )
}
