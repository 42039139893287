import React from 'react'
import { FormikProps } from 'formik'
import { Button, Divider, FlatList, Text, View } from 'native-base'

import { Alert } from '../../../components/Alert'
import { FormInput } from '../../../components/FormInput'
import { OrderProductItem } from './ProductItem'
import { IProduct } from '../../../data/interfaces/product'
import { InitialValuesProps } from '../../../data/interfaces/order'

interface IProductsList extends Partial<FormikProps<InitialValuesProps>> {
  products: IProduct[]
  noUnavailable?: boolean
}

export const OrderProductsList = ({
  values,
  products,
  submitForm,
  setFieldValue,
  noUnavailable,
}: IProductsList) => {
  const [search, setSearch] = React.useState<string>('')
  const [selecteds, setSelecteds] = React.useState<IProduct[]>(
    values?.products ?? []
  )

  const handleSend = () => {
    if (setFieldValue && submitForm && selecteds.length >= 1) {
      setFieldValue('products', selecteds)
      submitForm()
    } else {
      Alert.alert('selecione ao menos um produto')
    }
  }

  const filteredList = search.length
    ? products.filter(
        (item) =>
          (item.name &&
            item.code &&
            item.name.toLowerCase().includes(search.toLowerCase())) ||
          item.code.toLowerCase().includes(search.toLowerCase())
      )
    : products

  const chooseSelecteds = (value: { isSelected: boolean; item: IProduct }) => {
    const newList = value.isSelected
      ? [...selecteds, value.item]
      : selecteds.filter((item) => item.id !== value.item.id)
    setSelecteds(newList)
  }

  const renderItem = ({
    item,
  }: {
    item: InitialValuesProps['products'][0]
  }) => {
    const isSelected = !!selecteds.find((i) => i.id === item.id)
    let quantity = item?.storages?.length ? 0 : undefined

    if (quantity !== undefined) {
      item?.storages?.forEach((p) => {
        quantity = (quantity ?? 0) + (p.quantity ?? 0)
      })
    }

    const unavailable = !noUnavailable && (!quantity || quantity === 0)

    return (
      <>
        <OrderProductItem
          notSelectable={unavailable}
          opacity={unavailable ? 0.5 : 1}
          item={item}
          isSelected={isSelected}
          selectedItem={unavailable ? undefined : chooseSelecteds}
        />
        {quantity !== undefined && (
          <View
            top={'12'}
            right={'12'}
            position={'absolute'}
            alignSelf={'flex-end'}
          >
            <Text>{`Total: ${quantity}`}</Text>
          </View>
        )}
      </>
    )
  }

  return (
    <>
      <FormInput value={search} onChangeText={setSearch} />

      <FlatList
        data={filteredList}
        renderItem={renderItem}
        showsVerticalScrollIndicator={false}
        ItemSeparatorComponent={() => <Divider />}
        ListFooterComponent={<View height={'20'} />}
        ListEmptyComponent={<Text textAlign={'center'}>Nenhum resultado</Text>}
      />

      <Divider height={'1.5'} />
      <View height={'16'} justifyContent={'center'}>
        <Button width={'2/5'} onPress={handleSend} alignSelf={'flex-end'}>
          Próximo
        </Button>
      </View>
    </>
  )
}
