import React, { useState } from 'react'
import { format, differenceInYears } from 'date-fns'
import { Card, HStack, Icon, IconButton, Text, VStack, View } from 'native-base'
import { IHStackProps } from 'native-base/lib/typescript/components/primitives/Stack/HStack'
import { Entypo, AntDesign } from '@expo/vector-icons'

import { IClient } from '../../../data/interfaces/client'
import { convertCurrency } from '../../../util/currencyConverter'
import { formatDate } from '../../../util/formatDate'

interface IProductItem extends IHStackProps {
  editClient: (client: IClient) => void
  deleteClient: (client: IClient) => void
  item: IClient
}

export const ClientItem = ({
  item,
  editClient,
  deleteClient,
  ...props
}: IProductItem) => {
  const [showDeleteConfirmation, setShowDeleteConfirmation] = useState(false)
  const getBirthDate = () => {
    if (!item.birthDate) return null

    const birthDate = new Date(formatDate(item.birthDate))

    return {
      day: format(birthDate, 'dd/MM'),
      age: differenceInYears(new Date(), birthDate),
    }
  }

  const anniversary = getBirthDate()

  return (
    <Card margin={'2'}>
      <HStack {...props} margin={'1'} justifyContent={'space-between'}>
        <VStack paddingX={'2'} maxW={'70%'}>
          <Text>{`${item.name}`}</Text>

          {item.phone && <Text>{`Contato: ${item.phone}`}</Text>}

          {anniversary && (
            <HStack>
              <Text>{`Aniversário: ${anniversary.day} - `}</Text>
              <Text>{`${anniversary.age} anos`}</Text>
            </HStack>
          )}

          {item.credit && item.credit > 0 ? (
            <Text>{`Crédito: ${convertCurrency(Number(item.credit))}`}</Text>
          ) : null}
        </VStack>

        {showDeleteConfirmation ? (
          <View w={'30%'}>
            <Text>Excluir cliente?</Text>
            <HStack justifyContent={'space-between'}>
              <IconButton
                icon={<Icon as={Entypo} name="check" />}
                onPress={() => deleteClient(item)}
              />
              <IconButton
                icon={<Icon as={AntDesign} name="close" color={'red.400'} />}
                onPress={() => setShowDeleteConfirmation(false)}
              />
            </HStack>
          </View>
        ) : (
          <HStack w={'25%'} justifyContent={'space-between'}>
            <IconButton
              icon={<Icon as={Entypo} name="pencil" />}
              onPress={() => editClient(item)}
            />
            <IconButton
              icon={<Icon as={Entypo} name="trash" color={'red.400'} />}
              onPress={() => setShowDeleteConfirmation(true)}
            />
          </HStack>
        )}
      </HStack>
    </Card>
  )
}
