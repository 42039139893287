import React, { useState } from 'react'
import { useWindowDimensions } from 'react-native'
import {
  Button,
  Checkbox,
  Divider,
  FlatList,
  HStack,
  Icon,
  Modal,
  Text,
  View,
  VStack,
  IModalProps,
  Spacer,
} from 'native-base'
import { FormInput } from '../../../components/FormInput'
import { Masks } from 'react-native-mask-input'
import { Formik } from 'formik'
import { AntDesign } from '@expo/vector-icons'
import * as Yup from 'yup'
import { Alert } from '../../../components/Alert'
import { IClient } from '../../../data/interfaces/client'
import { uidGen } from '../../../util/uidGenerator'

interface IClientsModal extends IModalProps {
  data: IClient[]
  selectedClient: (client: IClient) => void
}

export const ClientsModal = ({
  selectedClient,
  data,
  ...props
}: IClientsModal) => {
  const { height, width } = useWindowDimensions()
  const [clients, setClients] = useState<IClient[]>(data)
  const [selected, setSelected] = useState<string | undefined>('')
  const [search, setSearch] = useState<string>('')

  const filteredList = search
    ? clients.filter((item) =>
        item.name.toLowerCase().includes(search.toLowerCase())
      )
    : clients

  const handleSend = () => {
    if (!selected) Alert.alert('Selecione um cliente')
    else {
      selectedClient(clients.find((item) => item.id === selected) as IClient)
      props.onClose()
    }
  }

  const renderItem = ({ item, index }: { item: IClient; index: number }) => (
    <>
      <HStack justifyContent={'space-between'} height={'16'} padding={'2'}>
        <View>
          <Text>{item.name}</Text>
          <Text>{item.phone}</Text>
        </View>
        <Checkbox
          marginTop={'8'}
          value={item.name}
          isChecked={selected === item.id}
          onChange={() =>
            selected === item.id ? setSelected(undefined) : setSelected(item.id)
          }
          aria-labelledby={`${item.name}${index}`}
        />
      </HStack>
      <Divider />
    </>
  )

  return (
    <Modal {...props}>
      <Modal.Content width={width} height={height} padding={'3'}>
        <Modal.CloseButton onPress={props.onClose} />
        <FormInput
          width={'5/6'}
          value={search}
          marginBottom={'4'}
          placeholder="Buscar"
          onChangeText={setSearch}
        />
        <FlatList data={filteredList} renderItem={renderItem} />
        <Formik
          initialValues={{
            name: '',
            phone: '',
            birthDate: '',
          }}
          onSubmit={(client, { resetForm }) => {
            const id = uidGen()
            setClients([
              ...clients,
              {
                ...client,
                id,
              },
            ])
            setSelected(id)
            resetForm()
          }}
          validationSchema={Yup.object().shape({
            name: Yup.string().required('Campo obrigatório'),
          })}
        >
          {({ values, setFieldValue, errors, submitForm }) => (
            <Modal.Footer bg={'primary.100'}>
              <VStack width={'full'}>
                <FormInput
                  label="Nome"
                  value={values.name}
                  onChangeText={(name) => setFieldValue('name', name)}
                  errorMessage={errors.name}
                />
                <FormInput
                  label="Telefone"
                  keyboardType="phone-pad"
                  value={values.phone}
                  mask={Masks.BRL_PHONE}
                  onChangeText={(phone) => setFieldValue('phone', phone)}
                />
              </VStack>

              <HStack width={'full'} justifyContent={'space-between'}>
                <FormInput
                  label="Data de nascimento"
                  keyboardType="number-pad"
                  containerProps={{
                    marginRight: '2',
                  }}
                  value={values.birthDate}
                  mask={Masks.DATE_DDMMYYYY}
                  onChangeText={(birthDate) =>
                    setFieldValue('birthDate', birthDate)
                  }
                />
                <HStack width={'2/5'} height={'10'} marginTop={'4'}>
                  <Button
                    onPress={submitForm}
                    isDisabled={!values.name.length}
                    rightIcon={<Icon name="plus" as={AntDesign} />}
                  />

                  <Spacer />

                  <Button width={'3/5'} onPress={handleSend}>
                    Ok
                  </Button>
                </HStack>
              </HStack>
            </Modal.Footer>
          )}
        </Formik>
      </Modal.Content>
    </Modal>
  )
}
