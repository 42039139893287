import React, { useState } from 'react'
import { FormInput } from '../../../components/FormInput'
import { Loading } from '../../../components/Loading'
import { FlatList } from 'native-base'
import { IClient } from '../../../data/interfaces/client'
import { ClientItem } from './ClientItem'

interface IClientsList {
  isLoading: boolean
  clients: IClient[]
  handleEdit: (client: IClient) => void
  handleDelete: (client: IClient) => void
}

export const ClientsList = ({
  isLoading,
  clients,
  handleDelete,
  handleEdit,
}: IClientsList) => {
  const [search, setSearch] = useState<string>('')

  const filteredClients = search.length
    ? clients?.filter(
        (client) =>
          client.name.toLowerCase().includes(search.toLowerCase()) ||
          String(client.birthDate)?.toLowerCase().includes(search.toLowerCase())
      )
    : clients

  const renderItem = ({ item }: { item: IClient }) => (
    <ClientItem
      item={item}
      editClient={handleEdit}
      deleteClient={handleDelete}
    />
  )

  return (
    <>
      <FormInput placeholder="Buscar" value={search} onChangeText={setSearch} />

      {isLoading ? (
        <Loading />
      ) : (
        <FlatList data={filteredClients ?? []} renderItem={renderItem} />
      )}
    </>
  )
}
