import React from 'react'
import { Container } from '../../components/Container'
import { useQuery } from '@tanstack/react-query'
import { Sale } from '../../data/models/sale'
import { Card, Divider, FlatList, Text, View } from 'native-base'
import { ISale } from '../../data/interfaces/sale'
import { Loading } from '../../components/Loading'
import { convertCurrency } from '../../util/currencyConverter'
import { paymentMethods } from '../../data/constants/paymentMethods'

export const MySales = () => {
  const { data: sales, isLoading: loadingSales } = useQuery(['sales'], () =>
    Sale.getAll()
  )

  const renderItem = ({ item }: { item: ISale }) => {
    const total = item.products.reduce(
      (acc, p) => acc + (p.price ?? 0) * (p.quantity ?? 1),
      0
    )
    const discount = (Number(item.discount) ?? 1) / 100
    const totalWithDiscount = total - total * discount
    const paymentMethod = paymentMethods.find(
      (method) => method.id === item.paymentMethod
    )?.name
    const parcells =
      item.paymentMethod === '2' && item.methodDetails
        ? ` em ${item.methodDetails}x`
        : ''
    const anotherMethod =
      item.paymentMethod === '99' && item.methodDetails
        ? `${item.methodDetails}`
        : ''

    return (
      <Card margin={'2'}>
        <Text alignSelf={'flex-end'}>{item.date}</Text>

        <Text>{`Pagamento: ${anotherMethod || paymentMethod}${parcells}`}</Text>
        {item.client && <Text>{`Cliente: ${item.client?.name}`}</Text>}
        {item.useCredit && (
          <Text>{`Crédito utilizado: ${item.useCredit}`}</Text>
        )}

        <Divider marginY={'2'} />

        {item.products.map((product) => (
          <Text key={product.id}>{`${product.quantity}x ${product.name}`}</Text>
        ))}

        <Divider width={'4/5'} />
        <View width={'4/5'} alignItems={'flex-end'}>
          <Text textDecorationLine={item.discount && 'line-through'}>
            {`Total: ${convertCurrency(total)}`}
          </Text>

          {item.discount ? (
            <>
              <Text>{`desconto de ${item.discount}%`}</Text>
              <Text>{`Total: ${convertCurrency(totalWithDiscount)}`}</Text>
            </>
          ) : null}
        </View>
      </Card>
    )
  }

  if (loadingSales) return <Loading />
  return (
    <Container>
      <FlatList data={sales ?? []} renderItem={renderItem} />
    </Container>
  )
}
