import React, { useContext } from 'react'
import { useMutation } from '@tanstack/react-query'
import {
  HStack,
  HamburgerIcon,
  Icon,
  IconButton,
  Menu,
  Pressable,
  Spinner,
  Text,
} from 'native-base'
import { AntDesign } from '@expo/vector-icons'
import { User } from '../data/models/user'
import { Product } from '../data/models/product'
import { MaterialCommunityIcons } from '@expo/vector-icons'
import { UserContext } from '../contexts/User'

export const HeaderOptions = () => {
  const { user } = useContext(UserContext)
  const isMasterUser = !!user?.isMasterUser

  const { mutate: logout } = useMutation({
    mutationFn: User.logout,
  })

  const { mutate: createSyncData, isLoading: loadingSync } = useMutation({
    mutationFn: Product.createSyncData,
  })

  const syncIcon = (
    <Icon
      marginX={'4'}
      size={'md'}
      as={MaterialCommunityIcons}
      name="database-sync"
    />
  )

  return (
    <Menu
      trigger={(triggerProps) => (
        <Pressable marginX={'4'} {...triggerProps}>
          <HamburgerIcon color={'black'} />
        </Pressable>
      )}
    >
      <Menu.Item height={'10'} justifyContent={'center'}>
        <HStack>
          <IconButton
            onPress={() => logout()}
            icon={
              <Icon marginX={'4'} size={'md'} as={AntDesign} name="logout" />
            }
          />

          <Text alignSelf={'center'}>Sair</Text>
        </HStack>
      </Menu.Item>
      {isMasterUser && (
        <Menu.Item height={'10'} justifyContent={'center'}>
          <HStack justifyContent={'space-between'}>
            {loadingSync ? (
              <Spinner color={'primary.400'} />
            ) : (
              <IconButton onPress={() => createSyncData()} icon={syncIcon} />
            )}

            <Text alignSelf={'center'}>Sincronizar</Text>
          </HStack>
        </Menu.Item>
      )}
    </Menu>
  )
}
