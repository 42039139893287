import React from 'react'
import { NativeBaseProvider } from 'native-base'
import { QueryClient, QueryClientProvider } from '@tanstack/react-query'

import Router from './src/router'
import { UserContextProvider } from './src/contexts/User'

export default function App() {
  const queryClient = new QueryClient()
  return (
    <QueryClientProvider client={queryClient}>
      <UserContextProvider>
        <NativeBaseProvider>
          <Router />
        </NativeBaseProvider>
      </UserContextProvider>
    </QueryClientProvider>
  )
}
