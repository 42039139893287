import React, { useLayoutEffect, useState } from 'react'
import { Formik } from 'formik'
import { AntDesign } from '@expo/vector-icons'
import { Icon, IconButton } from 'native-base'
import { useNavigation } from '@react-navigation/native'
import { useMutation, useQuery } from '@tanstack/react-query'

import { Finish } from './Components/Finish'
import { Alert } from '../../components/Alert'
import { Loading } from '../../components/Loading'
import { Product } from '../../data/models/product'
import { Storage } from '../../data/models/storage'
import { Quantifier } from './Components/Quantifier'
import { Container } from '../../components/Container'
import { OrderProductsList } from './Components/ProductsList'
import { Order as OrderClass } from '../../data/models/order'
import { IOrder, InitialValuesProps } from '../../data/interfaces/order'
import { format } from 'date-fns'

const headerTitles: Record<number, string> = {
  1: 'Produtos',
  2: 'Quantidade',
  3: 'Detalhes',
}

export const Order = () => {
  const [step, setStep] = useState(1)
  const navigation = useNavigation()

  const { data: products } = useQuery(['products'], () => Product.getAll())

  const initialValues: InitialValuesProps = {
    date: '',
    products: [],
    discount: '',
    orderName: '',
  }

  const { data: orders, isLoading: loadingOrders } = useQuery(['orders'], () =>
    OrderClass.getAll()
  )

  const { isLoading: loadingSend, mutate: sendOrder } = useMutation({
    mutationFn: (data: IOrder) => OrderClass.create(data),
    onSuccess: (data, order) => storageProducts(order),
  })

  const { mutate: storageProducts, isLoading: loadingStorage } = useMutation({
    mutationFn: (data: IOrder) => Storage.addFromOrder(data),
    onSuccess: () => {
      Alert.alert('Pedido enviado com sucesso!')
      navigation.goBack()
    },
  })

  const handleSend = (values: InitialValuesProps) => {
    const discount =
      !values.discount || isNaN(Number(values?.discount))
        ? 0
        : Number(values?.discount)

    const sendData: IOrder = {
      discount,
      date: values.date ?? format(new Date(), 'dd/MM/yyyy'),
      products: values.products ?? [],
      orderName: values.orderName ?? '',
    }

    sendOrder(sendData)
  }

  useLayoutEffect(() => {
    navigation.setOptions({
      headerLeft: () => (
        <IconButton
          onPress={() =>
            step === 1 ? navigation.goBack() : setStep((v) => v - 1)
          }
          icon={<Icon as={AntDesign} name="arrowleft" size={6} color="black" />}
        />
      ),
      headerTitle: `Pedido - ${headerTitles[step]}`,
    })
  })

  if (loadingOrders) return <Loading />
  return (
    <Formik<InitialValuesProps>
      initialValues={initialValues}
      onSubmit={(values) =>
        step === 3 ? handleSend(values) : setStep((v) => v + 1)
      }
    >
      {({ setFieldValue, submitForm, values }) => (
        <Container>
          {step === 1 && (
            <OrderProductsList
              noUnavailable
              values={values}
              submitForm={submitForm}
              products={products ?? []}
              setFieldValue={setFieldValue}
            />
          )}

          {step === 2 && (
            <Quantifier
              values={values}
              submitForm={submitForm}
              setFieldValue={setFieldValue}
            />
          )}

          {step === 3 && (
            <Finish
              values={values}
              orders={orders ?? []}
              submitForm={submitForm}
              setFieldValue={setFieldValue}
              buttonLoading={loadingSend || loadingOrders || loadingStorage}
            />
          )}
        </Container>
      )}
    </Formik>
  )
}
