import {
  collection,
  doc,
  getDocs,
  query,
  setDoc,
  updateDoc,
  where,
} from 'firebase/firestore'
import { IProduct } from '../interfaces/product'
import { auth, db } from '../../services/firebase'
import { IOrder } from '../interfaces/order'

class StorageModel {
  public async getAll() {
    const signature = auth.currentUser?.uid
    const data: IProduct[] = []

    const queryProducts = await getDocs(
      query(collection(db, 'storages'), where('signature', '==', signature))
    )

    queryProducts.forEach((doc) => data.push(doc.data() as IProduct))

    return data
  }

  public async addFromOrder(value: IOrder) {
    const signature = auth.currentUser?.uid
    const { products, ...order } = value

    const currentProducts = await this.getAll()

    const newProducts: IProduct[] = []

    products.forEach((product) => {
      const { quantity, ...rest } = product

      const currentProduct = currentProducts.find(
        (cProduct) => cProduct?.id === product.id
      )

      const newStorage = {
        ...order,
        quantity,
      }

      newProducts.push({
        ...rest,
        storages:
          currentProduct && currentProduct.storages
            ? [...currentProduct.storages, newStorage]
            : [newStorage],
      })
    })

    const promises = newProducts.map((product) => {
      return setDoc(doc(db, 'storages', product.id), { ...product, signature })
    })

    await Promise.all(promises)
  }

  public async update(product: IProduct) {
    const signature = auth.currentUser?.uid
    const productRef = doc(db, 'storages', product.id)
    return updateDoc(productRef, { ...product, signature })
  }
}

export const Storage = new StorageModel()
