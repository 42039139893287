import { initializeApp } from 'firebase/app'
import { getAnalytics } from 'firebase/analytics'
import { getFirestore } from 'firebase/firestore'
import { getAuth } from 'firebase/auth'

const firebaseConfig = {
  apiKey: 'AIzaSyAi6laXXrKJb_5fGXLP0K3ckMviLERGLj8',
  authDomain: 'mariah-sa.firebaseapp.com',
  projectId: 'mariah-sa',
  storageBucket: 'mariah-sa.appspot.com',
  messagingSenderId: '426420810552',
  appId: '1:426420810552:web:b7cc68d1fcd6d3928547cd',
  measurementId: 'G-F4XCVPMWRT',
}

const app = initializeApp(firebaseConfig)
export const analytics = getAnalytics(app)
export const db = getFirestore(app)
export const auth = getAuth(app)
