import React from 'react'
import { createNativeStackNavigator } from '@react-navigation/native-stack'

import { Sale } from '../../screens/Sale'
import { Home } from '../../screens/Home'
import { Order } from '../../screens/Order'
import { ParamListBase } from '@react-navigation/native'
import { MyClients } from '../../screens/MyClients'
import { MySales } from '../../screens/MySales'
import { MyOrders } from '../../screens/MyOrders'
import { HeaderOptions } from '../../components/HeaderOptions'

export interface MainStackProps extends ParamListBase {
  Sale: undefined
  Order: undefined
  MySales: undefined
  MyOrders: undefined
  MyStorage: undefined
  MyClients: undefined
}

const MainStack = createNativeStackNavigator<MainStackProps>()

export const MainStackRouter = () => {
  return (
    <MainStack.Navigator>
      <MainStack.Screen
        name="Home"
        component={Home}
        options={{
          headerRight: () => <HeaderOptions />,
        }}
      />

      <MainStack.Screen
        name="MySales"
        component={MySales}
        options={{
          title: 'Minhas Vendas',
        }}
      />

      <MainStack.Screen
        name="MyClients"
        component={MyClients}
        options={{
          title: 'Meus clientes',
        }}
      />

      <MainStack.Screen
        name="MyOrders"
        component={MyOrders}
        options={{
          title: 'Meus pedidos',
        }}
      />

      <MainStack.Screen name="Sale" component={Sale} />
      <MainStack.Screen name="Order" component={Order} />
    </MainStack.Navigator>
  )
}
