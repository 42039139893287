import React from 'react'
import { FormikProps } from 'formik'
import { useWindowDimensions } from 'react-native'
import { Button, Divider, FlatList, HStack, Text, View } from 'native-base'
import { InitialValuesProps } from '../../../data/interfaces/sale'
import { ClientsModal } from './ClientsModal'
import { FormInput } from '../../../components/FormInput'
import { Cashier } from '../../../components/Cashier'
import { IClient } from '../../../data/interfaces/client'
import { convertCurrency } from '../../../util/currencyConverter'

export const Client = ({
  clients,
  values,
  loadingSend,
  setFieldValue,
  submitForm,
}: Partial<FormikProps<InitialValuesProps>> & {
  clients: IClient[]
  loadingSend: boolean
}) => {
  const { height } = useWindowDimensions()
  const [showModal, setShowModal] = React.useState(false)

  const renderItem = ({
    item,
  }: {
    item: InitialValuesProps['products'][0]
  }) => {
    return (
      <HStack justifyContent={'space-between'}>
        <Text>{`${item.name} - ${item.code}`}</Text>
        <Text>{`${item.quantity}`}</Text>
      </HStack>
    )
  }

  return (
    <>
      <View flex={1}>
        <ClientsModal
          data={clients}
          isOpen={showModal}
          onClose={() => setShowModal(false)}
          selectedClient={(client) =>
            setFieldValue && setFieldValue('client', client)
          }
        />
        <FlatList
          ListHeaderComponent={() => (
            <>
              <HStack justifyContent={'space-between'}>
                <Text>Produto</Text>
                <Text>Quantidade</Text>
              </HStack>
            </>
          )}
          data={values?.products}
          renderItem={renderItem}
          contentContainerStyle={{ height: height * 0.5 }}
        />

        {values?.client && (
          <Button
            height={'10'}
            width={'1/3'}
            alignSelf={'flex-end'}
            bg={'red.500'}
            onPress={() => setFieldValue && setFieldValue('client', undefined)}
          >
            Remover cliente
          </Button>
        )}

        <Divider marginBottom={'2'} />

        <HStack width={'full'} marginBottom={'2'} alignSelf={'flex-end'}>
          <FormInput
            value={values?.client?.name ?? ''}
            label="Cliente"
            editable={false}
            containerProps={{
              width: '2/3',
              marginRight: '4',
            }}
          />

          <Button
            height={'10'}
            marginTop={'4'}
            onPress={() => setShowModal(true)}
          >
            {values?.client?.name ? 'Alterar' : 'Adicionar'}
          </Button>
        </HStack>

        {values?.client?.credit && values?.client?.credit > 0 && (
          <HStack justifyContent={'space-between'}>
            <Text>
              {`Crédito disponível \n${
                (values?.client?.credit &&
                  convertCurrency(values?.client?.credit)) ??
                'R$ 0,00'
              }`}
            </Text>
            <FormInput
              label="Utilizar"
              keyboardType="number-pad"
              onChangeText={(useCredit) =>
                setFieldValue && setFieldValue('useCredit', useCredit)
              }
              isDisabled={!values?.client?.credit}
              style={
                (values?.useCredit &&
                  values?.client?.credit &&
                  values?.useCredit > values?.client?.credit && {
                    backgroundColor: '#ff000050',
                  }) ||
                undefined
              }
            />
          </HStack>
        )}
      </View>

      <Cashier
        isFinish
        values={values}
        isLoading={loadingSend}
        handleNext={submitForm}
      />
    </>
  )
}
