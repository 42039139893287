import React, { useLayoutEffect, useState } from 'react'
import { Formik } from 'formik'
import { AntDesign } from '@expo/vector-icons'
import { Icon, IconButton } from 'native-base'
import { useMutation, useQuery } from '@tanstack/react-query'
import { useNavigation } from '@react-navigation/native'
import * as Yup from 'yup'

import { Alert } from '../../components/Alert'
import { Payment } from './Components/Payment'
import { Client } from './Components/Client'
import { Loading } from '../../components/Loading'
import { Storage } from '../../data/models/storage'
import { Container } from '../../components/Container'
import { ISale, InitialValuesProps } from '../../data/interfaces/sale'
import { Sale as SaleModel } from '../../data/models/sale'
import { Client as ClientModel } from '../../data/models/client'
import { SaleProductsList } from './Components/ProductsList'
import { Quantifier } from './Components/Quantifier'

const headerTitles: Record<number, string> = {
  1: 'Produtos',
  2: 'Quantidade',
  3: 'Pagamento',
  4: 'Cliente',
}

const initialValues: InitialValuesProps = {
  date: '',
  products: [],
  discount: '',
  client: null,
  paymentMethod: '',
  methodDetails: '',
}

const validation: Record<number, unknown> = {
  3: Yup.object().shape({
    paymentMethod: Yup.string().required('Campo obrigatório'),
    date: Yup.string().min(10, 'Preencha a data completa'),
  }),
}

export const Sale = () => {
  const navigation = useNavigation()
  const [step, setStep] = useState(1)

  useLayoutEffect(() => {
    navigation.setOptions({
      headerLeft: () => (
        <IconButton
          onPress={() =>
            step === 1 ? navigation.goBack() : setStep((v) => v - 1)
          }
          icon={<Icon as={AntDesign} name="arrowleft" size={6} color="black" />}
        />
      ),
      headerTitle: `Venda - ${headerTitles[step]}`,
    })
  })

  const { data: clients, isLoading: loadingClients } = useQuery(
    ['clients'],
    () => ClientModel.getAll()
  )

  const { data: products, isLoading: loadingProducts } = useQuery(
    ['sorage-products'],
    () => Storage.getAll()
  )

  const { isLoading: loadingSend, mutate: createSale } = useMutation({
    mutationFn: (data: ISale) => SaleModel.create(data, clients ?? []),
    onSuccess: () => {
      Alert.alert('Venda realizada com sucesso!')
      navigation.goBack()
    },
    onError: () => {
      Alert.alert('Ocorreu um erro ao tentar realizar a venda!')
    },
  })

  const handleSend = (values: InitialValuesProps) => {
    const getNewCreditValue = () => {
      if (values.client?.credit && values.useCredit)
        return values.client.credit - values.useCredit
      return values.client?.credit
    }

    if (values.client && values.client.credit && values.useCredit) {
      const newCreditValue = getNewCreditValue()
      createSale({
        ...values,
        client: { ...values.client, credit: newCreditValue },
      })
    } else {
      createSale(values)
    }
  }

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={validation[step]}
      onSubmit={(values, { resetForm }) => {
        if (step !== 4) setStep((v) => v + 1)
        else {
          handleSend(values)
          resetForm()
        }
      }}
    >
      {({ values, setFieldValue, submitForm, errors }) =>
        loadingProducts || loadingClients ? (
          <Loading />
        ) : (
          <Container>
            {step === 1 && (
              <SaleProductsList
                values={values}
                submitForm={submitForm}
                products={products ?? []}
                setFieldValue={setFieldValue}
              />
            )}

            {step === 2 && (
              <Quantifier
                values={values}
                submitForm={submitForm}
                setFieldValue={setFieldValue}
              />
            )}

            {step === 3 && (
              <Payment
                errors={errors}
                values={values}
                submitForm={submitForm}
                setFieldValue={setFieldValue}
              />
            )}

            {step === 4 && (
              <Client
                values={values}
                submitForm={submitForm}
                clients={clients ?? []}
                loadingSend={loadingSend}
                setFieldValue={setFieldValue}
              />
            )}
          </Container>
        )
      }
    </Formik>
  )
}
