import React from 'react'
import { useWindowDimensions } from 'react-native'
import { Button, HStack, Modal, VStack, IModalProps, View } from 'native-base'
import { FormInput } from '../../../components/FormInput'
import { Masks } from 'react-native-mask-input'
import { Formik } from 'formik'
import * as Yup from 'yup'
import { uidGen } from '../../../util/uidGenerator'
import { IClient } from '../../../data/interfaces/client'

interface IClientsModal extends IModalProps {
  initialData: IClient
  handleAdd: (client: IClient) => void
  handleEdit: (client: IClient) => void
  isLoading: boolean
}

export const ClientsModal = ({
  handleAdd,
  handleEdit,
  isLoading,
  initialData,
  ...props
}: IClientsModal) => {
  const { width } = useWindowDimensions()
  const isEditing = !!initialData.name.length

  return (
    <Modal {...props}>
      <Modal.Content width={width} padding={'3'}>
        <Modal.CloseButton onPress={props.onClose} />
        <View height={'6'} />
        <Formik
          enableReinitialize
          initialValues={initialData}
          onSubmit={(client, { resetForm }) => {
            const id = uidGen()
            const data = { ...client, credit: Number(client.credit) }
            isEditing ? handleEdit(data) : handleAdd({ ...data, id })
            resetForm()
          }}
          validationSchema={Yup.object().shape({
            name: Yup.string().required('Campo obrigatório'),
            birthDate: Yup.string().min(10, 'Preencha a data completa'),
          })}
        >
          {({ values, setFieldValue, errors, submitForm }) => (
            <>
              <VStack width={'full'}>
                <FormInput
                  label="Nome"
                  value={values.name}
                  onChangeText={(name) => setFieldValue('name', name)}
                  errorMessage={errors.name}
                />
                <FormInput
                  label="Telefone"
                  keyboardType="phone-pad"
                  value={values.phone}
                  mask={Masks.BRL_PHONE}
                  onChangeText={(phone) => setFieldValue('phone', phone)}
                />
              </VStack>

              <HStack width={'full'} justifyContent={'space-between'}>
                <FormInput
                  containerProps={{
                    marginRight: '2',
                    width: '50%',
                  }}
                  keyboardType="number-pad"
                  value={values.birthDate}
                  label="Data de nascimento"
                  mask={Masks.DATE_DDMMYYYY}
                  onChangeText={(birthDate) =>
                    setFieldValue('birthDate', birthDate)
                  }
                  errorMessage={errors.birthDate}
                />

                <FormInput
                  label="Crédito"
                  keyboardType="number-pad"
                  containerProps={{
                    width: '40%',
                  }}
                  value={values.credit === 0 ? '' : String(values.credit)}
                  onChangeText={(credit) => setFieldValue('credit', credit)}
                />
              </HStack>
              <Button
                height={'10'}
                marginTop={'4'}
                onPress={submitForm}
                isLoading={isLoading}
                isDisabled={!values.name.length}
              >
                {isEditing ? 'Atualizar' : 'Adicionar'}
              </Button>
            </>
          )}
        </Formik>
      </Modal.Content>
    </Modal>
  )
}
