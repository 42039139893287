import { Button, HStack, Text, View } from 'native-base'
import React from 'react'
import { convertCurrency } from '../util/currencyConverter'
import { InitialValuesProps } from '../data/interfaces/sale'

export const Cashier = ({
  values,
  isFinish,
  isLoading,
  handleNext,
}: {
  handleNext?: () => void
  values?: InitialValuesProps
  isFinish?: boolean
  isLoading?: boolean
}) => {
  const total =
    values?.products.reduce(
      (acc, item) => acc + (item.price ?? 0) * (item?.quantity ?? 1),
      0
    ) ?? 0

  const discountValue = isNaN(Number(values?.discount))
    ? 0
    : Number(values?.discount) / 100

  const totalWithDiscount = total - total * discountValue

  return (
    <HStack marginY={'4'}>
      <HStack
        flex={1}
        height={'10'}
        paddingX={'2'}
        marginRight={'4'}
        borderRadius={'sm'}
        bgColor={'primary.500'}
        alignItems={'flex-end'}
        justifyContent={'space-between'}
      >
        <View>
          <Text>Total</Text>
          <Text>{convertCurrency(total)}</Text>
        </View>

        <View>
          <Text>c/ desconto</Text>
          <Text>{convertCurrency(totalWithDiscount)}</Text>
        </View>
      </HStack>

      <Button isLoading={isLoading} width={'2/5'} onPress={handleNext}>
        {isFinish ? 'Finalizar' : 'Próximo'}
      </Button>
    </HStack>
  )
}
