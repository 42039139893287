import React from 'react'

import { Button, Divider, Icon, Text, View } from 'native-base'
import { Container } from '../../components/Container'
import { useNavigation } from '@react-navigation/native'
import { NavigationMainProps } from '../../data/interfaces/router'
import {
  MaterialCommunityIcons,
  MaterialIcons,
  Ionicons,
  AntDesign,
} from '@expo/vector-icons'

export const Home = () => {
  const navigation = useNavigation<NavigationMainProps>()

  const menuButtonStyle = {
    height: '12',
    margin: '2',
  }

  return (
    <Container>
      <View flex={1}>
        <Text>Cadastrar</Text>

        <Divider />

        <Button
          {...menuButtonStyle}
          leftIcon={
            <Icon as={MaterialCommunityIcons} name="clipboard-edit-outline" />
          }
          onPress={() => navigation.navigate('Order')}
        >
          Pedido
        </Button>

        <Button
          {...menuButtonStyle}
          leftIcon={<Icon as={MaterialIcons} name="point-of-sale" />}
          onPress={() => navigation.navigate('Sale')}
        >
          Venda
        </Button>

        <Text marginTop={'4'}>Consultar</Text>

        <Divider />

        <Button
          {...menuButtonStyle}
          leftIcon={<Icon as={AntDesign} name="areachart" />}
          onPress={() => navigation.navigate('MySales')}
        >
          Minhas vendas
        </Button>

        <Button
          {...menuButtonStyle}
          leftIcon={<Icon as={MaterialIcons} name="storage" />}
          onPress={() => navigation.navigate('MyOrders')}
        >
          Meus pedidos
        </Button>

        <Button
          {...menuButtonStyle}
          leftIcon={<Icon as={Ionicons} name="people" />}
          onPress={() => navigation.navigate('MyClients')}
        >
          Meus clientes
        </Button>
      </View>
    </Container>
  )
}
